import React, { FC, useState } from "react";
import styled from "styled-components";
import { FEATURE_ITEMS_00 } from "./featuresData";

import { WrapperColored, ContainerColored, FlexBox } from "../global";
import { Dialog, Typography } from "@material-ui/core";
import _PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Video from "../common/layout/video";

const Features: FC = () => {
  const features = FEATURE_ITEMS_00;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [featureVideo, setFeatureVideo] = useState("https://player.vimeo.com/video/468337951");

  const handleOpen = videoURL => {
    setFeatureVideo(videoURL);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <FeaturesWrapper>
      <FeaturesContainer>
        <Typography variant="h3" align="center">
          <b>Features</b>
        </Typography>
        <FeaturesFlex>
          {features.map(featureItem => {
            return (
              <FeatureItem key={featureItem.header}>
                <FeatureImage onClick={() => handleOpen(featureItem.videoURL)}>
                  <img src={featureItem.image} alt={featureItem.header} decoding="async" />
                  <PlayCircleOutlineIcon />
                </FeatureImage>
                <FeatureContent>
                  <Typography variant="h4" align="center">
                    {featureItem.header}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ paddingTop: "16px", margin: "auto", maxWidth: "300px" }}
                  >
                    {featureItem.content}
                  </Typography>
                </FeatureContent>
              </FeatureItem>
            );
          })}
          <Dialog open={modalIsOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
            <div style={{ width: "100%", backgroundColor: "black" }}>
              <Video title="What is Ako Maps" src={featureVideo} />
            </div>
          </Dialog>
        </FeaturesFlex>
      </FeaturesContainer>
    </FeaturesWrapper>
  );
};

export default Features;

const FeaturesWrapper = styled(WrapperColored)`
  display: grid;
`;

const FeaturesContainer = styled(ContainerColored)``;
const FeaturesFlex = styled(FlexBox)`
  margin-left: auto;
`;
const FeatureItem = styled.div`
  position: relative;
  width: 45%;
  margin: 0 16px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 4px 8px;
    width: 100%;
  }
`;
const FeatureContent = styled.div`
  color: ${props => props.theme.color.text.onBackground};
`;
const FeatureImage = styled.div`
  display: flex;
  position: relative;
  margin: auto;
  height: 250px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  > img {
    transition: all 500ms cubic-bezier(0.47, 0.08, 0.54, 0.99);
    height: 90%;
  }
  > svg {
    transition: all 500ms cubic-bezier(0.47, 0.08, 0.54, 0.99);c
  }
  :hover {
    > img {
      height: 100%;
      opacity: 0.9;
    }
    > svg {
      opacity: 0.8;
    }
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 0px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    height: 150px;
  }
`;

const PlayCircleOutlineIcon = styled(_PlayCircleOutlineIcon)`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  height: 40%;
  width: 40%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  opacity: 0;
`;
