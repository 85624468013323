import React, { FC, useState } from "react";
import styled from "styled-components";
import Button from "@studytools/core/ui/components/Button";

import { Wrapper, Container } from "../global";
import { Dialog, Grid as _Grid, Typography } from "@material-ui/core";
import Video from "../common/layout/video";
import mapControlVideo from "../../videos/MapCtrlVid.mp4";

const Features: FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Wrapper>
      <Container>
        <Grid container direction="row">
          <TextContainer>
            <Typography variant="h5" align="center">
              Browse the map, then click a topic to navigate directly to that topic in the video
            </Typography>
            <Button onClick={handleOpen} fill="blue" style={{ maxWidth: 200, marginTop: "32px" }}>
              <Typography variant="h6">Watch Demo</Typography>
            </Button>
            <Dialog open={modalIsOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
              <div style={{ width: "100%", backgroundColor: "black" }}>
                <Video title="What is Ako Maps" src="https://player.vimeo.com/video/596828330" />
              </div>
            </Dialog>
          </TextContainer>
          <MediaContainer>
            <Typography variant="h3" align="center" color="primary" style={{ padding: "0 0 32px" }}>
              <b>Map-controlled Navigation</b>
            </Typography>
            <video
              autoPlay
              loop
              muted
              playsInline
              id="mapLinking-video"
              src={mapControlVideo}
              style={{ width: "100%", marginTop: "16px" }}
            />
          </MediaContainer>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Features;

const MediaContainer = styled.div`
  width: 70%;
  max-width: 1080px;
  min-width: 265px;
  padding: 0 16px 0;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  padding: 143px 16px 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;

const Grid = styled(_Grid)`
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column-reverse;
  }
`;
