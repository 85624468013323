import React, { FC } from "react";
import styled from "styled-components";
import { signUp } from "../common/links/signup";
import { WrapperColored, ContainerColored, Subtitle } from "../global";
import Button from "@studytools/core/ui/components/Button";
import { Typography, Grid } from "@material-ui/core";

const GetStarted: FC = () => {
  const handleClick = event => {
    event.preventDefault();
    signUp();
  };

  return (
    <WrapperColored id="getStarted">
      <ContainerColored>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h3" align="center">
            <b>Join Our Community Of Learners</b>
          </Typography>
          <Typography variant="body1" align="center" style={{ margin: "48px 0" }}>
            Sign up today and get FREE premium access for 12 months!
          </Typography>
          <Grid item style={{ width: 300 }}>
            <Button onClick={handleClick} fill="white">
              <Typography variant="h6">Sign up free</Typography>
            </Button>
          </Grid>
        </Grid>
      </ContainerColored>
    </WrapperColored>
  );
};

export default GetStarted;
