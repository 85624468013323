import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

import logos from "../../images/trusted/LogosBlack.svg";

import { Wrapper, Container } from "../global";
import { Typography } from "@material-ui/core";

const Trusted: FC = () => {
  return (
    <Wrapper id="trusted">
      <Container>
        <Typography variant="h3" align="center">
          <b>Our Customers</b>
        </Typography>
        <FeatureItem>
          <FeatureImage />
        </FeatureItem>
      </Container>
    </Wrapper>
  );
};

export default Trusted;

const slide = keyframes`
  from { background-position-x: 1700px; }
  to   { background-position-x: 0; }
`;
const slideMobile = keyframes`
  from { background-position-x: 1200px; }
  to   { background-position-x: 0; }
`;
const FeatureItem = styled.div`
  padding: 32px 0;
  width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const FeatureImage = styled.div`
  width: 1700px;
  height: 100px;
  background-image: url(${logos});
  background-size: 1700px 100px;
  animation: ${slide} 40s linear infinite;
  opacity: 0.85;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 1200px;
    background-size: 1200px 80px;
    height: 80px;
    animation: ${slideMobile} 30s linear infinite;
  }
`;
