import React, { FC, useState } from "react";
import styled from "styled-components";
import Button from "@studytools/core/ui/components/Button";

import { WrapperColored as _WrapperColored, ContainerColored } from "../global";
import { Dialog, Grid, Typography } from "@material-ui/core";

import videoMapVideo from "../../videos/VidCtrlMap.mp4";
import Video from "../common/layout/video";

const Features: FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <WrapperColored>
      <ContainerColored>
        <Grid container direction="row">
          <MediaContainer>
            <Typography variant="h3" align="center" style={{ padding: "0 0 32px" }}>
              <b>Video-to-map linking</b>
            </Typography>
            <video
              autoPlay
              loop
              muted
              playsInline
              id="videoMapLinking-video"
              src={videoMapVideo}
              style={{ width: "100%", marginTop: "16px" }}
            />
          </MediaContainer>
          <TextContainer>
            <Typography variant="h5" align="center">
              Ako Maps can be &apos;linked&apos; to lessons. As the video plays, it gives context by showing ideas in
              the map.
            </Typography>
            <Button onClick={handleOpen} fill="white" style={{ maxWidth: 200, marginTop: "32px" }}>
              <Typography variant="h6">Watch Demo</Typography>
            </Button>
            <Dialog open={modalIsOpen} onClose={handleClose} maxWidth={"lg"} fullWidth>
              <div style={{ width: "100%", backgroundColor: "black" }}>
                <Video title="What is Ako Maps" src="https://player.vimeo.com/video/596921306" />
              </div>
            </Dialog>
          </TextContainer>
        </Grid>
      </ContainerColored>
    </WrapperColored>
  );
};

export default Features;

const WrapperColored = styled(_WrapperColored)`
  box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.4);
`;

const MediaContainer = styled.div`
  width: 70%;
  max-width: 1080px;
  min-width: 265px;
  padding: 0 16px 0;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  padding: 143px 16px 0;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;
