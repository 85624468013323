import React, { useState, useEffect, FC } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Header from "../components/sections/header";
import Features from "../components/sections/features";
import Resources from "../components/sections/resources";
import Trusted from "../components/sections/trusted";
import GetStarted from "../components/sections/getStarted";
import Footer from "../components/sections/footer";
import InTheClassroom from "../components/sections/inTheClassroom";
import VideoMapLinking from "../components/sections/videoMapLinking";
import MapControlledNavigation from "../components/sections/mapControlledNavigation";
import CallToAction from "../components/sections/callToAction";
import Demo from "../components/sections/demo";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";
import { CourseResult } from "../queries/course";

const courseIds = ["609dfce7c0d296292221247f", "5ef5864daf5ed70845e76fb1", "5f064b3a83c1ea05707a16f3"];

const query = `{${courseIds.map((id, index) => `c${index + 1}: course (courseId: "${id}")${CourseResult}`).join(" ")}}`;

const IndexPage: FC = () => {
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    const options = {
      method: "POST",
      body: JSON.stringify({ query }),
      headers: new Headers({ "content-type": "application/json" }),
    };
    fetch("/graphql", options)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then(data => setCourseList(data.data))
      .catch(console.error);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Navigation />
        <Header />
        <VideoMapLinking />
        <MapControlledNavigation />
        <Features />
        <Trusted />
        {/* <Demo />
        <CallToAction /> */}
        <InTheClassroom />
        <Resources courseList={courseList} />
        <GetStarted />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
